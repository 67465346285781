// src/App.tsx
import React, { useState }  from 'react';
import Chat from './components/Chat';
import Login from './components/Login';
import { createTheme, ThemeProvider, CssBaseline } from '@mui/material';

const darkTheme = createTheme({
  palette: {
    mode: 'dark', // Switching the dark mode on
    // You can also customize other theme properties like primary, secondary colors
    primary: {
      main: '#f6ff00', // Default primary color
    },
  },
});

const App: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  return (
    <ThemeProvider theme={darkTheme}>
      <div className="App">
        <header className="App-header">
          {/* align center */}
          {/* <img className='logo' alt='IOP Logo' src="https://iconsofporsche.com/images/iop-logo-white.svg"></img> */}
          <h3 style={{ textAlign: 'center' }}>Turbo, your personal Icons of Porsche assistant</h3>
          <p style={{ textAlign: 'center' }}>Ask me anything about Icons of Porsche 2024</p>
        </header>
        <main>
        <Chat/>
        <br />        
        </main>
      </div>
    </ThemeProvider>
  );
};

export default App;
